import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  DealInformation,
  LoadVehicleSummary,
  ModalConfirm,
  SummaryHeader,
  VehicleInformation,
} from "components/app";
import { InformativeModal } from "components/Modal/InformativeModal";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Banner, Breadcrumbs, Button, Modal } from "components/shared";
import { Timer } from "components/Timer";
import { VehicleComments } from "components/VehicleNotes";
import { VehiclePhotoContainer } from "components/VehiclePhotoContainer";

import { AuthContext } from "context/AuthContext";

import {
  ActionResult,
  ActionResultMessage,
  ERROR_MESSAGE,
  FLOW_TYPE,
  formatDateToJsDate,
  MOTOR_MARKET_SOLD_AUCTION,
  showErrorToast,
  showSuccessToast,
  VEHICLE_SUMMARY_TITLE,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IMotorMarket, IMotorMarketGroupVehicle } from "types/IMotorMarket";

import { AcquisitionService, MotorMarketService } from "api/client";

import { BidsHistory } from "./BidsHistory";

interface StateProps {
  selectedVehicle: IMotorMarket;
  draftVehicle: IMotorMarketGroupVehicle;
}

export const Summary = () => {
  const { userID, hasPermission } = useContext(AuthContext);
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isCancelSaleModalOpen, setIsCancelSaleModalOpen] = useState(false);
  const [isCancelSaleWarningOpen, setIsCancelSaleWarningOpen] = useState(false);

  const selectedVehicle =
    (state as StateProps)?.selectedVehicle ||
    (state as StateProps)?.draftVehicle;

  const contractID = params.id;

  const canView = hasPermission(
    PermissionCategory.MOTOR_MARKET_SELLING,
    PermissionAction.VIEW
  );

  const canDelete = hasPermission(
    PermissionCategory.MOTOR_MARKET_SELLING,
    PermissionAction.DELETE
  );

  const {
    data: vehicleSummary,
    isLoading: isLoadingVehicleSummary,
    isError: isErrorVehicleSummary,
    isSuccess: isSuccessVehicleSummary,
    refetch: vehicleSummaryRefetch,
  } = useQuery(
    [
      `fetch_detailed_summary_data_${userID}`,
      contractID,
      {
        with: "dealership,owner,seller,assessments,valuations,images,buyer,comment,expenses",
      },
    ],
    AcquisitionService.getAcquisitionSummary,
    {
      cacheTime: 0,
      enabled: canView,
    }
  );

  const { mutate: removeVehicle, isLoading: isRemovingVehicle } = useMutation(
    () =>
      MotorMarketService.deleteVehicle(
        vehicleSummary?.data.auction_vehicle_id || ""
      ),
    {
      onSuccess: () => {
        showSuccessToast("Vehicle Removed");
        setIsRemoveModalOpen(false);
        navigate(-1);
      },
      onError: () => {
        showErrorToast("Failed to Remove");
      },
    }
  );

  const { mutate: cancelAuction, isLoading: isLoadingCancelAuction } =
    useMutation(
      () =>
        AcquisitionService.revertAuction(contractID || "", {
          reason: "Sale has been cancelled.",
        }),
      {
        onSuccess: () => {
          showSuccessToast("Successfully Cancelled");
          setIsRemoveModalOpen(false);
          navigate(-1);
        },
        onError: () => {
          setIsRemoveModalOpen(false);
          setIsCancelSaleWarningOpen(true);
        },
      }
    );

  const renderSummary = () => {
    if (!canView) {
      return (
        <NoPermissionMessage
          viewPermissionCategory={PermissionCategory.MOTOR_MARKET_SELLING}
        />
      );
    }

    if (isLoadingVehicleSummary) {
      return <LoadVehicleSummary />;
    }

    if (isErrorVehicleSummary) return <div>{ERROR_MESSAGE}</div>;

    if (isSuccessVehicleSummary && vehicleSummary?.data) {
      return (
        <>
          <div className="dashboard__content--left">
            <SummaryHeader
              data={vehicleSummary.data}
              onRefresh={vehicleSummaryRefetch}
            />
            <DealInformation
              data={vehicleSummary.data}
              defaultOpen
              onRefresh={vehicleSummaryRefetch}
              auctionID={vehicleSummary.data?.auction_vehicle_id}
              flow={
                vehicleSummary.data?.owner?.id
                  ? FLOW_TYPE.RETAIL_DEALERSHIP
                  : FLOW_TYPE.WHOLESALE_DEALERSHIP
              }
            />
            <VehicleInformation
              data={vehicleSummary.data}
              refetch={vehicleSummaryRefetch}
            />
            <VehiclePhotoContainer data={vehicleSummary.data} />
          </div>
          <div className="dashboard__content--right">
            <Banner
              name={vehicleSummary.data.status.name}
              label={vehicleSummary.data.status.label}
            />
            {vehicleSummary.data.status.name !==
              MOTOR_MARKET_SOLD_AUCTION.key && (
              <div className="mb-6">
                {selectedVehicle?.ends_at && (
                  <Timer
                    targetDate={formatDateToJsDate(selectedVehicle?.ends_at)}
                    isSummaryView
                  />
                )}
                <BidsHistory
                  vehicleAuctionID={vehicleSummary.data?.auction_vehicle_id}
                  selectedVehicle={selectedVehicle}
                  panelView
                  refetchSummary={vehicleSummaryRefetch}
                />
              </div>
            )}
            {vehicleSummary.data.status.name ===
            MOTOR_MARKET_SOLD_AUCTION.key ? (
              hasPermission(
                PermissionCategory.MOTOR_MARKET_SELLING,
                PermissionAction.SELL
              ) && (
                <div>
                  <Button
                    type="button"
                    className="!bg-white !w-36 shadow-md"
                    onClick={() => setIsCancelSaleModalOpen(true)}
                  >
                    <span className="text-primary font-bold text-sm uppercase">
                      Cancel Sale
                    </span>
                  </Button>
                </div>
              )
            ) : (
              <div className="dashboard__content--right">
                {canDelete && (
                  <Button
                    type="button"
                    className="!bg-white !w-64 shadow-md"
                    onClick={() => setIsRemoveModalOpen(true)}
                  >
                    <span className="text-primary font-bold text-sm uppercase">
                      Remove from MotorMarket
                    </span>
                  </Button>
                )}
              </div>
            )}
            <VehicleComments
              contractID={vehicleSummary.data.contract_id}
              canComment={canView}
            />
          </div>
          <Modal
            open={isRemoveModalOpen}
            closeModal={() => setIsRemoveModalOpen(false)}
            size="auto"
          >
            <ModalConfirm
              isLoading={isRemovingVehicle}
              title={
                ActionResultMessage[ActionResult.RemoveMotorMarketVehicle].title
              }
              description={
                ActionResultMessage[ActionResult.RemoveMotorMarketVehicle]
                  .message
              }
              onCancel={() => setIsRemoveModalOpen(false)}
              onConfirm={() => removeVehicle()}
            />
          </Modal>
          <Modal
            open={isCancelSaleModalOpen}
            closeModal={() => setIsCancelSaleModalOpen(false)}
            size="auto"
          >
            <ModalConfirm
              isLoading={isLoadingCancelAuction}
              title={
                ActionResultMessage[ActionResult.CancelMotorMarketSale].title
              }
              description={
                ActionResultMessage[ActionResult.CancelMotorMarketSale].message
              }
              onCancel={() => setIsCancelSaleModalOpen(false)}
              onConfirm={() => cancelAuction()}
            />
          </Modal>
          <Modal
            open={isCancelSaleWarningOpen}
            closeModal={() => setIsCancelSaleWarningOpen(false)}
            size="auto"
          >
            <InformativeModal
              content={{
                title:
                  ActionResultMessage[ActionResult.WarningCancelMotorMarketSale]
                    .title,
                message:
                  ActionResultMessage[ActionResult.WarningCancelMotorMarketSale]
                    .message,
                icon: "FailedIcon",
              }}
              onClose={() => setIsCancelSaleWarningOpen(false)}
            />
          </Modal>
        </>
      );
    }

    return <></>;
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Stocklist", SECTIONS.MOTORMARKET)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="dashboard__header">
          <Breadcrumbs
            paths={[
              {
                title: "MotorMarket",
                route: "/motor-market",
              },
              {
                title: "My Listings",
                route: "/motor-market/my-listings",
              },
              {
                title: VEHICLE_SUMMARY_TITLE,
                route: `/motor-market/my-listings/${contractID}/summary`,
              },
            ]}
          />
        </div>
        <div className="dashboard__content">{renderSummary()}</div>
      </div>
    </>
  );
};
