import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import Ably from "ably";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ButtonLink, Modal, SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { NotificationContext } from "context/NotificationContext";

import {
  ABLY_NOTIFICATIONS_EVENT,
  ACCEPTED,
  ACQUIRED,
  AWAITING,
  BUYING,
  BUYNOW,
  DECLINED,
  DECLINED_VALUATION,
  DRAFT,
  getTenderURL,
  LOST,
  LOSTSALE,
  MOTOR_MARKET_AUCTION,
  MOTOR_MARKET_WON,
  NEGOTIATING,
  PENDING,
  REJECTED,
  REVIEW,
  REVOKED,
  SELLING,
  showErrorToast,
  SOLDTENDERED,
  SOLDTODEALERSHIP,
  SOLDTOMP,
  SOLDTORETAIL,
  StorageKeys,
  TENDER_MENU,
  TENDERED,
  VERIFICATION_APPROVED,
  VERIFICATION_DECLINED,
  VERIFICATION_PENDING,
  WON,
} from "utils";

import {
  INotification,
  INotificationResponseType,
  NOTIFICATION_BODY_TYPE,
  NOTIFICATION_TYPE,
  TENDER_NOTIFICATIONS,
} from "types/INotification";

import {
  InspectionService,
  NotificationService,
  ValuationService,
} from "api/client";

import { useLocalStorage } from "hooks";

import { ModalNotification } from "../..";

export const ButtonNotification = () => {
  const { user } = useContext(AuthContext);
  const { channel } = useContext(NotificationContext);
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useLocalStorage(
    StorageKeys.NotificationsUnreadState,
    false
  );
  const [lastTimeNotificationsChecked, setLastTimeNotificationsChecked] =
    useLocalStorage(StorageKeys.LastTimeNotificationsChecked, null);

  const {
    data,
    hasNextPage,
    refetch,
    fetchNextPage,
    isError,
    isInitialLoading,
    isFetching,
  } = useInfiniteQuery<INotificationResponseType, Error>(
    ["fetchNotifications"],
    {
      queryFn: NotificationService.getNotifications,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.meta.current_page + 1;
        return lastPage.meta.last_page >= nextPage ? nextPage : undefined;
      },
    }
  );

  const { mutate } = useMutation(
    (id: string) => NotificationService.handleMarkReadNotification(id),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const notificationItems = useMemo(
    () => data?.pages?.map((page) => page.data).flat() || [],
    [data?.pages]
  );

  useEffect(() => {
    const unreadNotifications = notificationItems.find(
      (notification) => !notification.read_at
    );

    if (
      unreadNotifications &&
      (!lastTimeNotificationsChecked ||
        lastTimeNotificationsChecked < unreadNotifications.created_timestamp)
    ) {
      setHasUnreadNotifications(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (channel)
      channel.subscribe(
        ABLY_NOTIFICATIONS_EVENT,
        (_data: Ably.Types.Message) => {
          const contractID = _data?.data?.contract_id;
          if (contractID) {
            queryClient.invalidateQueries([`retail-summary-${contractID}`]);
            queryClient.invalidateQueries([`wholesale-summary-${contractID}`]);
            queryClient.invalidateQueries(["direct-offer-logs"]);
          }
          setHasUnreadNotifications(true);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const handleScrollHitBottom = () => {
    fetchNextPage();
  };

  const handleShowNotifications = () => {
    refetch();
    setShowNotificationModal(true);
    setLastTimeNotificationsChecked(new Date().toISOString());
    setHasUnreadNotifications(false);
  };

  const handleReadNotification = async (item: INotification) => {
    mutate(item.id);

    try {
      if (item.event === NOTIFICATION_TYPE.AcquisitionRevertedNotification)
        return;

      if (
        item.body.type === NOTIFICATION_BODY_TYPE.BUYER_EXTERNAL_REQUEST ||
        item.body.type === NOTIFICATION_BODY_TYPE.DEALERSHIP_ACQUISITION ||
        item.body.type === NOTIFICATION_BODY_TYPE.SELLER_EXTERNAL_REQUEST ||
        (item.body.type === NOTIFICATION_BODY_TYPE.DIRECT_OFFER &&
          [
            NOTIFICATION_TYPE.QuickSellReceivedNotification,
            NOTIFICATION_TYPE.QuickSellUpdatedNotification,
            "",
          ].includes(item.event))
      ) {
        let vehicleData;
        if (
          item.body.type === NOTIFICATION_BODY_TYPE.DEALERSHIP_ACQUISITION ||
          item.body.type === NOTIFICATION_BODY_TYPE.SELLER_EXTERNAL_REQUEST
        ) {
          vehicleData = await InspectionService.getVehicleSummary({
            queryKey: ["fetch", item.body.contract_id],
          });
        } else {
          vehicleData = await ValuationService.getValuationRequestSummary({
            queryKey: [
              "fetch",
              item.body.contract_id,
              {},
              item.body.request_id,
              "valuation",
            ],
          });
        }

        const updatedStatus = vehicleData.data.status.name;

        switch (updatedStatus) {
          case PENDING.key:
          case BUYNOW.key:
            setShowNotificationModal(false);
            return navigate(
              `/wholesale-dealership/valuation/${item.body.contract_id}/${item.body.request_id}/summary`
            );
          case LOSTSALE.key:
          case REVOKED.key:
          case REJECTED.key:
          case LOST.key:
            setShowNotificationModal(false);
            return navigate(
              `/wholesale-dealership/valued/${item.body.contract_id}/${item.body.request_id}/summary`
            );
          case ACQUIRED.key:
            setShowNotificationModal(false);

            if (
              !vehicleData.data.is_contract_owner &&
              item.body.type ===
                NOTIFICATION_BODY_TYPE.SELLER_EXTERNAL_REQUEST &&
              (updatedStatus !== ACQUIRED.key ||
                item.event === NOTIFICATION_TYPE.OfferAmountAddedNotification)
            ) {
              return navigate(
                `/wholesale-dealership/${
                  vehicleData?.data?.valuations?.internal?.offer?.valuation_id
                    ? "valued"
                    : "valuation"
                }/${item.body.contract_id}/${item.body.request_id}/summary`
              );
            }

            return vehicleData.data.is_contract_owner
              ? navigate(
                  `/${
                    vehicleData.data.retailed ? "retail" : "wholesale"
                  }-dealership/stocklist/${item.body.contract_id}/summary`
                )
              : navigate(
                  `/wholesale-dealership/stocklist/${item.body.contract_id}/summary`
                );

          case TENDERED.key:
          case WON.key:
          case SOLDTOMP.key:
          case SOLDTORETAIL.key:
          case SOLDTODEALERSHIP.key:
          case SOLDTENDERED.key:
            setShowNotificationModal(false);
            return navigate(
              `/wholesale-dealership/stocklist/${item.body.contract_id}/${item.body.request_id}/summary`
            );
          case ACCEPTED.key:
            setShowNotificationModal(false);
            return navigate(
              `/retail-dealership/valued/${item.body.contract_id}/summary`
            );
          case AWAITING.key:
          case NEGOTIATING.key:
            setShowNotificationModal(false);
            if (
              vehicleData.data.retailed &&
              vehicleData.data.is_contract_owner
            ) {
              return navigate(
                `/retail-dealership/valuation/${item.body.contract_id}/summary`
              );
            } else {
              return navigate(
                `/wholesale-dealership/valuation/${item.body.contract_id}/${item.body.request_id}/summary`
              );
            }
          case MOTOR_MARKET_AUCTION.key:
            if (
              vehicleData?.data?.is_contract_owner &&
              item?.body.contract_status?.slug === ACQUIRED.key
            ) {
              setShowNotificationModal(false);
              return navigate(
                `${
                  vehicleData?.data?.retailed ? "/retail" : "/wholesale"
                }-dealership/stocklist/${
                  vehicleData?.data?.contract_id
                }/summary`
              );
            }
            break;
          default:
            break;
        }
      } else if (
        TENDER_NOTIFICATIONS.findIndex((noti) => noti === item.event) < 0
      ) {
        if (
          item.event === NOTIFICATION_TYPE.AuctionWonNotification ||
          item.event === NOTIFICATION_TYPE.AuctionOutbidNotification
        ) {
          const isOutBidNotification =
            item.event === NOTIFICATION_TYPE.AuctionOutbidNotification;
          setShowNotificationModal(false);

          return navigate(
            `/motor-market/buying/${item?.body?.contract_id}/summary`,
            {
              state: {
                selectedVehicle: {
                  ...item.body,
                  status: {
                    name: isOutBidNotification
                      ? MOTOR_MARKET_AUCTION.key
                      : MOTOR_MARKET_WON.key,
                    label: isOutBidNotification
                      ? MOTOR_MARKET_AUCTION.label
                      : MOTOR_MARKET_WON.label,
                  },
                },
              },
            }
          );
        }

        if (item.event === NOTIFICATION_TYPE.AuctionEndingNotification) {
          setShowNotificationModal(false);
          return navigate(`/motor-market/my-listings`, {
            state: {
              filterStatus: "live",
            },
          });
        }

        if (item.event === NOTIFICATION_TYPE.WatchlistEndingNotification) {
          setShowNotificationModal(false);
          return navigate(`/motor-market/buying`, {
            state: {
              filterStatus: "watchlist",
            },
          });
        }

        if (item.event === NOTIFICATION_TYPE.AuctionEndedNotification) {
          setShowNotificationModal(false);
          return navigate(`/motor-market/my-listings`, {
            state: {
              filterStatus: "ended",
            },
          });
        }

        if (
          item.event === NOTIFICATION_TYPE.AuctionVehicleRemovedNotification
        ) {
          setShowNotificationModal(false);
          return navigate(`/motor-market/buying`);
        }

        const vehicleData = await InspectionService.getVehicleSummary({
          queryKey: ["fetch", item.body.contract_id],
        });

        const updatedStatus = vehicleData.data.status.name;

        switch (updatedStatus) {
          case AWAITING.key:
          case REVIEW.key:
          case DRAFT.key:
            setShowNotificationModal(false);
            return navigate(
              `/${
                vehicleData.data.retailed ? "retail" : "wholesale"
              }-dealership/valuation/${item.body.contract_id}/summary`
            );
          case DECLINED.key:
            setShowNotificationModal(false);
            return navigate(
              `/wholesale-dealership/valued/${item.body.contract_id}/summary`
            );
          case NEGOTIATING.key:
          case DECLINED_VALUATION.key:
            if (
              !item.body.requester_id ||
              user?.data?.dealership?.id === item.body.requester_id ||
              item.event ===
                NOTIFICATION_TYPE.InternalValuationAddedNotification
            ) {
              setShowNotificationModal(false);
              return navigate(
                `/retail-dealership/valued/${item.body.contract_id}/summary`
              );
            } else {
              break;
            }
          case ACQUIRED.key:
          case TENDERED.key:
          case SOLDTOMP.key:
          case SOLDTORETAIL.key:
          case SOLDTODEALERSHIP.key:
          case SOLDTENDERED.key:
            if (
              !item.body.requester_id ||
              user?.data?.dealership?.id === item.body.requester_id
            ) {
              setShowNotificationModal(false);
              return navigate(
                `/retail-dealership/stocklist/${item.body.contract_id}/summary`
              );
            } else {
              break;
            }
          case ACCEPTED.key:
            setShowNotificationModal(false);
            return navigate(
              `/retail-dealership/valued/${item.body.contract_id}/summary`
            );
          case VERIFICATION_PENDING.key:
            setShowNotificationModal(false);
            return navigate(
              `/wholesale-dealership/valuation/${item.body.contract_id}/summary`
            );
          case VERIFICATION_APPROVED.key:
          case VERIFICATION_DECLINED.key:
            setShowNotificationModal(false);
            return navigate(
              `/wholesale-dealership/valued/${item.body.contract_id}/summary`
            );
          default:
            break;
        }
      } else {
        setShowNotificationModal(false);

        const tenderFlow =
          item.body.dealership_id === user?.data.dealership?.id
            ? SELLING.name
            : BUYING.name;

        switch (item.event) {
          case NOTIFICATION_TYPE.VehicleWonNotification: {
            return navigate(
              `${TENDER_MENU.buying.route}/${item.body?.tender_id}/${item.body?.tender_vehicle_id}/summary`
            );
          }
          case NOTIFICATION_TYPE.TenderEndedNotification:
          case NOTIFICATION_TYPE.TenderStartedNotification:
          case NOTIFICATION_TYPE.TenderScheduledNotification:
          default:
            return navigate(getTenderURL(tenderFlow, item.body.tender_id));
        }
      }

      setShowNotificationModal(false);
    } catch (err) {
      console.log("notification deeplinking error: ", err);
    }

    showErrorToast("You can't see the details of this notification");
  };

  return (
    <>
      <ButtonLink onClick={handleShowNotifications}>
        <div className="btn-notification">
          <SvgImage name="RingIcon" width={26} height={29} />
          {hasUnreadNotifications && (
            <span className="btn-notification__icon"></span>
          )}
        </div>
      </ButtonLink>
      <Modal
        size="auto"
        open={showNotificationModal}
        closeModal={() => setShowNotificationModal(false)}
      >
        <ModalNotification
          hasNextPage={hasNextPage}
          isLoading={isInitialLoading || isFetching}
          isError={isError}
          notifications={notificationItems}
          onMarkRead={handleReadNotification}
          onScrollHitBottom={handleScrollHitBottom}
        />
      </Modal>
    </>
  );
};
